import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="About" />
      <div style={{ marginTop: `30px`, fontFamily: `Montserrat` }}>
        <h3 style={{ margin: 0, paddingBottom: `8px`}}>Welcome 🇺🇸 !</h3>
        <h3 style={{ margin: 0, paddingBottom: `8px` }}>Selamat Datang 🇮🇩 !</h3>
        <div style={{ marginTop: `10px`, marginBottom: `10px`, fontSize: 18 }}>
          <code style={{ fontSize: 18, }}>Abraham <u><strong>Dio</strong></u> Suharli</code>
          <br />
          <code style={{ fontSize: 14, }}>
            [<br />
              &nbsp;&nbsp;full-time 🧑‍💻,<br/>
              &nbsp;&nbsp;occasional 📸,<br />
              &nbsp;&nbsp;from 🇮🇩<br />
            ]</code>
          <br/>
        </div>
        <ul style={{ marginLeft: `20px`, fontSize: 15}}>
          <li>If you are curious about my projects, please checkout my <Link to={'/work'}>Work</Link></li>
          <li>If you want to check out my photos, go to&nbsp;<a href="https://photos.dio.fyi" target="blank">photos.dio.fyi</a></li>
          <li><a href="https://github.com/abrahamdio/" target="blank">github.com/abrahamdio</a></li>
          <li><a href="https://www.linkedin.com/in/abrahamdio/" target="blank">linkedin.com/in/abrahamdio</a></li>
        </ul>
        <div style={{ marginTop: `50px` }}>
          <i>Made with ❤️ &amp; ☕ in Cupertino</i>
        </div>
        <div style={{ marginTop: `10px` }}>
          <small>Inspired (and forked) from this awesome <a href="https://www.gatsbyjs.org/starters/iamtherealgd/gatsby-starter-22boxes-uno/">Gatsby Starter</a>!</small>
        </div>
        <br />
      </div>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
